import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const AboutUs = () => {
    return (
        <Fragment>
            <CommonHero>
                <StaticImage
                    src="../../../assets/images/about-us/philosophy.jpg"
                    alt=" yeliana nikolskaya"
                    width={846}
                    height={430}
                    loading="eager"
                />
            </CommonHero>
            <Article>
                <p>
                    Our staff is trained to perform all non-invasive cosmetic procedures, and that
                    has helped us develop the most comprehensive selection of non-surgical cosmetic
                    treatment options.
                </p>

                <p>
                    We are a boutique style Medical spa, we believe in the art of aesthetics, true
                    beauty of sincere connection with each client, individual approach, warm care,
                    listening to every concern, and working toward naturally-looking enhancement
                    together with each client. We welcome you to be a part of our Beauty Religion!
                </p>
            </Article>
        </Fragment>
    );
};

export default AboutUs;
